import "./src/styles/markdown.css"

require("prismjs/themes/prism-coy.css");
require("prismjs/plugins/line-numbers/prism-line-numbers.css");

// TRK: Current not in use, this confuses theme layout!
/*
const React = require("react")
const Layout = require("./src/components/layout").default

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
*/
